import React from "react";
import './BodyOmne.css';
import HeadlineOmne from './HeadlineOmne'

function BodyOmne() {

  return (
    <div>
      <HeadlineOmne/>

    </div>
  );
}

export default BodyOmne;
