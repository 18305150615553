import React from "react";
import './Sluzba.css';


function Sluzba(probs) {

  return (
    <div>
      <div className="sluzba">
        <div className="sluzba-box">
        <div className="sluzba-box-1"><img src={probs.Picture} alt="logo"/></div>
        <div className="sluzba-box-2">{probs.Headline}</div>
        <div className="sluzba-box-3">{probs.Text}</div>
        </div>
      </div>
    </div>
  );
}

export default Sluzba;
