import React from "react";
import './Footer.css';
import Phone from './Phone'

import Copyright from './Copyright'
import Devros from './Devros'


function Footer() {

  return (
    <div>
      <div className="footer">
      <Phone/><Devros/>
    
      <Copyright/>
      
      </div>
    </div>
  );
}

export default Footer;
