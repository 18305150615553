import React from "react";
import './Button2.css';

function Button2() {

  return (
    <div className="button2">
    <input type="submit" className="button2" value="Odeslat"></input>
    </div>
  );
}

export default Button2;
