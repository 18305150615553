import React from "react";
import './HeadlineVecirky.css';


function HeadlineVecirky() {

  return (
    <div className="headlinevecirky">
      <div className="headlinevecirky-box">
        <div className="headlinevecirky-box1"><p>Moderátor a DJ pro vaši firemní akci nebo večírek</p></div>
        <div className="headlinevecirky-box2">Večírky</div>
        <div className="headlinevecirky-box3">Také v životě firmy se najde důvod k oslavě, bilancování, poděkování spolupracovníkům a příjemnému uvolnění se z běžného pracovního koloběhu. Na základě Vašich požadavků a potřeb udělám všechno pro to, aby Vaši spolupracovníci poznali, že to s nimi myslíte.</div>
        <div className="headlinevecirky-box4"></div>
      </div>
    </div>
);
}

export default HeadlineVecirky;


