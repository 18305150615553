import React from "react";
import './PageHome.css';
import Header from './Header'
import BodyHome from './BodyHome'
import Footer from './Footer'

function PageHome() {

  return (
      <div>
      <Header/>
      <BodyHome/>
      <Footer/>
      </div>
  );
}

export default PageHome;
