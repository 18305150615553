import React from "react";
import './Logo.css';
import logo from './source/Logo.png'

function Logo() {

  return (
    <div className="logo">
      <div className="logo-img"><img className="logo-img-img" src={logo} alt="Vítězslav Řepa"/></div>
      <div className="logo-text-first">
        <div className="logo-text-first-1">V</div>
        <div className="logo-text-first-2">ítězslav</div>
      </div>
      <div className="logo-text-second">Repa</div>
    </div>
  );
}

export default Logo;
