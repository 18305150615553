import React from "react";
import './HeadlineKontakt.css';
import Formular from './Formular'
import kontaktfotka from './source/img19.png'


function HeadlineKontakt() {

  return (
    <div className="headlinekontakt">
        <div className="headlinekontakt-left">
            <div className="headlinekontakt-box-box">
            <div className="headlinekontakt-box1"><p>Pro ověření volného termínu využijte formulář nebo mi zavolejte na telefonní číslo</p></div>
            <div className="headlinekontakt-box2">Kontakt</div>
            <div className="headlinekontakt-box3">Telefon: &nbsp;<p>725 362 563</p></div>
            <div className="headlinekontakt-box4">Email: &nbsp;<p>repavitezslav@gmail.com</p></div>
            <div className="headlinekontakt-box5"><Formular/></div>
            
            </div> 
        </div>
        <div className="headlinekontakt-right"><img src={kontaktfotka} alt="Vítězslav Řepa"/></div>
    </div>
  );
}

export default HeadlineKontakt;


