import React from "react";
import './BodyOslavy.css';
import HeadlineOslavy from './HeadlineOslavy'

function BodyOslavy() {

  return (
    <div>
      <HeadlineOslavy/>

    </div>
  );
}

export default BodyOslavy;
