import React from "react";
import './BodyKontakt.css';
import HeadlineKontakt from './HeadlineKontakt'

function BodyKontakt() {

  return (
    <div>
      <HeadlineKontakt/>
    </div>
  );
}

export default BodyKontakt;
