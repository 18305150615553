import React from "react";
import './Devros.css';



function Devros() {

  return (
    <div className="devros">

      <div className="devrosB">
      <a className="devrosC" href="https://www.devros.cz/">DevRos</a>
      </div>
    </div>
  );
}

export default Devros;
