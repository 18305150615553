import React from "react";
import './Galerie.css';
import Fotka from './Fotka'
import img13 from './source/img13.png'
import img14 from './source/img14.png'
import img15 from './source/img15.png'
import img16 from './source/img16.png'
import img17 from './source/img17.png'
import img18 from './source/img18.png'


function Galerie() {

  return (
    <div className="galerie">
      <div className="galerie-nadpis">Fotoalbum</div> 
        <div className="galerie-box">
          <div className="galerie-box-box">
            <div className="galerie-box1">
            <Fotka
            Picture={img13}
            FotkaText="Valašské Klobouky"
            />
            </div>
            <div className="galerie-box2">
            <Fotka
            Picture={img14}
            FotkaText="Brumov-Bylnice"
            />
            </div>
            <div className="galerie-box3">
            <Fotka
            Picture={img15}
            FotkaText="Žilina"
            />
            </div>
            <div className="galerie-box4">
            <Fotka
            Picture={img16}
            FotkaText="Loučka"
            />
            </div>
            <div className="galerie-box5">
            <Fotka
            Picture={img17}
            FotkaText="Vysoké Pole"
            />
            </div>
            <div className="galerie-box6">
            <Fotka
            Picture={img18}
            FotkaText="Trnava"
            />
            </div>
          
        </div>
      </div>
    </div>
  );
}

export default Galerie;


