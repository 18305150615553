import React from "react";
import './Sluzby.css';
import Sluzba from './Sluzba'
import img9 from './source/img9.png'
import img10 from './source/img10.png'
import img11 from './source/img11.png'
import img13 from './source/img13.png'


function Sluzby() {

  return (
      <div className="sluzby">
        <div className="sluzby-box">
          <div className="sluzby-box1"><p>Co všechno u mě najdete</p></div>
          <div className="sluzby-box2">Nabízím následující služby</div>
          <div className="sluzby-box3">Pokud vás některá možnost zaujala, neváhejte mě kontaktovat. Domluvíme se na termínu.</div>
          <div className="sluzby-box4">
            <Sluzba
            Picture={img9}
            Headline="Svatby"
            Text="Váš svatební den se změní v nezapomenutelný. Moderní hudba a kvalitní ozvučení Vašeho dne. "
            />
            <Sluzba
            Picture={img10}
            Headline="Oslavy"
            Text="Zajistím Vám Vaši rodinnou oslavu, sraz se spolužáky, dětské karnevaly, dětské dny, ..."
            />
            <Sluzba
            Picture={img11}
            Headline="Večírky"
            Text="Firemní večírky, vánoční večírky, školní plesy, ..."
            />
            <Sluzba
            Picture={img13}
            Headline="Ozvučení"
            Text="Ozvučení obřadů, firemních akcí, aktivit na oslavách a svatbách."
            />
          </div>
        </div>
      </div>
  );
}

export default Sluzby;
