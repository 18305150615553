import React from "react";
import './Formular.css';
import emailjs from '@emailjs/browser';
import { useRef } from "react";
import Button2 from './Button2'

export const Formular = () => {

  const form = useRef();

  function sendEmail(e) {
      e.preventDefault();

  emailjs.sendForm('service_aft86zf', 'template_2t50xyp', form.current, 'IO9hyFSk5adaJrgeB')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }


  return (
    <div>
      <form ref={form} onSubmit={sendEmail}>
              <div className="kontakt-box-formular-grid">
                  <div className="kontakt-box-formular-grid-1">
                      <input type="text" className="kontakt-box-formular-grid-1-text" placeholder="&nbsp;&nbsp;Jméno a Příjmení" name="name"/>
                  </div>
                  <div className="kontakt-box-formular-grid-2">
                      <input type="email" className="kontakt-box-formular-grid-2-text" placeholder="&nbsp;&nbsp;Váš email" name="email"/>
                  </div>
                  <div className="kontakt-box-formular-grid-3" >
                    <select className="kontakt-box-formular-grid-3-text" name="subject" type="subject">
                      <option name="subject" value="Svatba">&nbsp;&nbsp;Svatba</option>
                      <option name="subject" value="Oslava">&nbsp;&nbsp;Oslava</option>
                      <option name="subject" value="Večírek">&nbsp;&nbsp;Večírek</option>
                      <option name="subject" value="Dotaz">&nbsp;&nbsp;Všeobecný dotaz</option>
                
                    </select>
                  </div>
                  <div className="kontakt-box-formular-grid-4">
                      <textarea className="kontakt-box-formular-grid-4-text" placeholder="&nbsp;&nbsp;Napiš mi" name="message"></textarea>
                  </div>
              </div>
              <div className="headlinekontakt-box6"><Button2/></div>
      </form>
    </div>
  );
}

export default Formular;


