import React from "react";
import './BodyHome.css';
import Main from './Main'
import Sluzby from './Sluzby'

function BodyHome() {

  return (
    <div>
       <Main/>
       <Sluzby/>
    </div>
  );
}

export default BodyHome;
