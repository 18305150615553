import React from "react";
import './MenuW.css';
import {Link} from "react-router-dom"
import MenuFacebook from './MenuFacebook'
import {useState} from 'react'
import HamburgerMenuOpenW from './source/hamburgermenuopenW.png'
import HamburgerMenuCloseW from './source/hamburgermenucloseW.png'

function MenuW() {

const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div>
      <div className="menu">
        <div className="menu-ul">
          <div className="menu-liW"><a href="*"><Link to="/">Domů</Link></a></div>
          <div className="menu-liW"><a href="*"><Link to="/PageSvatby">Svatby</Link></a></div>
          <div className="menu-liW"><a href="*"><Link to="/PageOslavy">Oslavy</Link></a></div>
          <div className="menu-liW"><a href="*"><Link to="/PageVecirky">Večírky</Link></a></div>
          <div className="menu-liW"><a href="*"><Link to="/PageOmne">O mně</Link></a></div>
          <div className="menu-liW"><a href="*"><Link to="/PageKontakt">Kontakt</Link></a></div>
        </div>  
        <MenuFacebook/>
      </div>
      <div className="menu-ul-smallW"><img src={HamburgerMenuOpenW} alt="logo" onClick={() => setToggleMenu(true)}/>
          {toggleMenu && (
                          <div className='menu-ul-small-overlayW'>
                            <div className="menu-ul-small-overlay-logoW">
                              <div className="menu-ul-small-overlay-logo-logoW"></div>
                              <img src={HamburgerMenuCloseW} className='menu-ul-small-overlay-close-imgW' alt="logo" onClick={() => setToggleMenu(false)} />
                            </div>
                            <div className='menu-ul-small-overlay-boxW'>           
                              <div className='menu-ul-small-overlay-linkW'><Link to="/" onClick={() => setToggleMenu(false)} >Domů</Link></div>
                              <div className='menu-ul-small-overlay-linkW'><Link to="/PageSvatby" onClick={() => setToggleMenu(false)}>Svatby</Link></div>
                              <div className='menu-ul-small-overlay-linkW'><Link to="/PageOslavy" onClick={() => setToggleMenu(false)}>Oslavy</Link></div>
                              <div className='menu-ul-small-overlay-linkW'><Link to="/PageVecirky" onClick={() => setToggleMenu(false)}>Večírky</Link></div>
                              <div className='menu-ul-small-overlay-linkW'><Link to="/PageOmne" onClick={() => setToggleMenu(false)}>O mně</Link></div>
                              <div className='menu-ul-small-overlay-linkW'><Link to="/PageKontakt" onClick={() => setToggleMenu(false)}>Kontakt</Link></div>
                            </div>
                          </div>
                          )}
        </div>
    </div>
  );
}

export default MenuW;
