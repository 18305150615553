import React from "react";
import './BodyVecirky.css';
import HeadlineVecirky from './HeadlineVecirky'

function BodyVecirky() {

  return (
    <div>
      <HeadlineVecirky/>

    </div>
  );
}

export default BodyVecirky;
