import React from "react";
import './PageOmne.css';
import HeaderW from './HeaderW'
import BodyOmne from './BodyOmne'
import Footer from './Footer'

function PageOmne() {

  return (
      <div>
      <HeaderW/>
      <BodyOmne/>
      <Footer/>
      </div>
  );
}

export default PageOmne;
