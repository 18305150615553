import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import {useState, useEffect} from 'react'
import './App.css';
import PageHome from './PageHome'
import PageKontakt from './PageKontakt';
import PageSvatby from './PageSvatby';
import PageOslavy from './PageOslavy';
import PageVecirky from './PageVecirky';
import PageOmne from './PageOmne';
import { PuffLoader } from "react-spinners";


function App() {

  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    setLoading(true) 
    setTimeout(()=>{
    setLoading(false)
    },3000)},[])

  return (
  
    <div className="loader">
    {loading ? (
      <PuffLoader  size={150} 
                   color="#b61331" 
                   loading={loading}/>
      ) : (
       
    <div className="app">
    <Router>
        <Routes>
        <Route path="/" element={<PageHome/>}/>
        <Route path="*" element={<PageHome/>} />
        <Route path="/PageSvatby" element={<PageSvatby/>}/>
        <Route path="/PageOslavy" element={<PageOslavy/>}/>
        <Route path="/PageVecirky" element={<PageVecirky/>}/>
        <Route path="/PageOmne" element={<PageOmne/>}/>
        <Route path="/PageKontakt" element={<PageKontakt/>}/>
        </Routes>
    </Router>
    </div>
   )}
   </div>
 ) ;
}

export default App;