import React from "react";
import './Phone.css';
import phone from './source/phone.png'
import email from './source/email.png'


function Phone() {

  return (
    <div className="phone">
      <img src={phone} alt="phone"/><div className="phone-phone">Telefon: 725 362 563</div>
      <img src={email} alt="email"/><div className="phone-email">Email: repavitezslav@gmail.com</div>
    </div>
  );
}

export default Phone;
