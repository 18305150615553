import React from "react";
import './Copyright.css';


function Copyright() {

  return (
    <div>
      <div className="copyright">
        Copyright © 2023 Dj Vítězslav Řepa. Všechna práva vyhrazena.
      </div>
    </div>
  );
}

export default Copyright;
