import React from "react";
import './PageVecirky.css';
import HeaderW from './HeaderW'
import BodyVecirky from './BodyVecirky'
import Footer from './Footer'

function PageVecirky() {

  return (
      <div>
      <HeaderW/>
      <BodyVecirky/>
      <Footer/>
      </div>
  );
}

export default PageVecirky;
