import React from "react";
import './Fotka.css';


function Fotka(probs) {

  return (
    <div>
      <div className="fotka">
        <div className="fotka-box">
          <div className="fotka-box-1">
            <img src={probs.Picture} alt="fotka"/>
          </div>
          <div className="fotka-box-tags">
            <div className="fotka-box-3">{probs.FotkaText}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fotka;
