import React from "react";
import './LogoW.css';
import logo from './source/LogoW.png'

function LogoW() {

  return (
    <div className="logoW">
      <div className="logo-imgW"><img className="logo-img-imgW" src={logo} alt="Vítězslav Řepa"/></div>
      <div className="logo-text-firstW">
        <div className="logo-text-first-1W">V</div>
        <div className="logoW-text-first-2W">ítězslav</div>
      </div>
      <div className="logoW-text-secondW">Repa</div>
    </div>
  );
}

export default LogoW;
