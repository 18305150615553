import React from "react";
import './Menu.css';
import {Link} from "react-router-dom"
import MenuFacebook from './MenuFacebook'
import {useState} from 'react'
import HamburgerMenuOpen from './source/hamburgermenuopen.png'
import HamburgerMenuClose from './source/hamburgermenuclose.png'

function Menu() {

const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div>
      <div className="menu">
        <div className="menu-ul">
          <div className="menu-li"><a href="*"><Link to="/">Domů</Link></a></div>
          <div className="menu-li"><a href="*"><Link to="/PageSvatby">Svatby</Link></a></div>
          <div className="menu-li"><a href="*"><Link to="/PageOslavy">Oslavy</Link></a></div>
          <div className="menu-li"><a href="*"><Link to="/PageVecirky">Večírky</Link></a></div>
          <div className="menu-li"><a href="*"><Link to="/PageOmne">O mně</Link></a></div>
          <div className="menu-li"><a href="*"><Link to="/PageKontakt">Kontakt</Link></a></div>
        </div>  
        <MenuFacebook/>
      </div>

      <div className="menu-ul-small"><img src={HamburgerMenuOpen} alt="logo" onClick={() => setToggleMenu(true)}/>
          {toggleMenu && (
                          <div className='menu-ul-small-overlay'>
                            <div className="menu-ul-small-overlay-logo">
                              <div className="menu-ul-small-overlay-logo-logo"></div>
                              <img src={HamburgerMenuClose} className='menu-ul-small-overlay-close-img' alt="logo" onClick={() => setToggleMenu(false)} />
                            </div>
                            <div className='menu-ul-small-overlay-box'>           
                              <div className='menu-ul-small-overlay-link'><Link to="/" onClick={() => setToggleMenu(false)}>Domů</Link></div>
                              <div className='menu-ul-small-overlay-link'><Link to="/PageSvatby" onClick={() => setToggleMenu(false)}>Svatby</Link></div>
                              <div className='menu-ul-small-overlay-link'><Link to="/PageOslavy" onClick={() => setToggleMenu(false)}>Oslavy</Link></div>
                              <div className='menu-ul-small-overlay-link'><Link to="/PageVecirky" onClick={() => setToggleMenu(false)}>Večírky</Link></div>
                              <div className='menu-ul-small-overlay-link'><Link to="/PageOmne" onClick={() => setToggleMenu(false)}>O mně</Link></div>
                              <div className='menu-ul-small-overlay-link'><Link to="/PageKontakt" onClick={() => setToggleMenu(false)}>Kontakt</Link></div>
                            </div>
                          </div>
                          )}
        </div>
    </div>
  );
}

export default Menu;
