import React from "react";
import './HeadlineOslavy.css';


function HeadlineOslavy() {

  return (
    <div className="headlineoslavy">
      <div className="headlineoslavy-box">
        <div className="headlineoslavy-box1"><p>Těším se na vaši oslavu nebo ples</p></div>
        <div className="headlineoslavy-box2">Oslavy</div>
        <div className="headlineoslavy-box3">Oslava, to je důvod potkat se s rodinou, kamarády, spolužáky nebo spoluobčany. Na oslavách se obvykle schází pestrá směsice lidí, kteří mají různé hudební chutě a tady se osvědčí bohatý a pestrý hudební archív, ze kterého vám jako DJ mohu namíchat pestrou kulisu k tanci.</div>
        <div className="headlineoslavy-box4"></div>
      </div>
    </div>
);
}


export default HeadlineOslavy;


