import React from "react";
import './Main.css';
import Button1 from './Button1'
import Nain from './source/img12.png'


function Main() {

  return (
      <div className="main">
        <div className="main-left">
          <div className="main-left-box">
            <div className="main-left-box-1">Vítězslav Řepa</div>
            <div className="main-left-box-2"><div className="main-left-box-2-line"></div></div>
            <div className="main-left-box-3">Moderátor, DJ pro Vaši akci<br/>Vaše radost - Moje starost</div>
            <div className="main-left-box-button"><Button1/></div>
          </div>
        </div>
        <div className="main-right">
          <img src={Nain} alt="Vítězslav Řepa"/>
        </div>
      </div>
  );
}

export default Main;


