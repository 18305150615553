import React from "react";
import './PageKontakt.css';
import HeaderW from './HeaderW'
import BodyKontakt from './BodyKontakt'
import Footer from './Footer'

function PageKontakt() {

  return (
      <div>
      <HeaderW/>
      <BodyKontakt/>
      <Footer/>
      </div>
  );
}

export default PageKontakt;
