import React from "react";
import './MenuFacebook.css';
import logo1 from './source/img1.png'
import logo2 from './source/img2.png'
import logo3 from './source/img3.png'
import logo4 from './source/img4.png'

function MenuFacebook() {

  return (
    <div className="menufacebook">
    <div className="menufacebook-img1"><img src={logo1} alt="Vítězslav Řepa"/><div className="menufacebook-img3"><a href="https://www.facebook.com/vitezslav.repa"><img src={logo3} alt="Vítězslav Řepa"/></a></div></div>
    <div className="menufacebook-img2"><img src={logo2} alt="Vítězslav Řepa"/><div className="menufacebook-img4"><a href="https://www.instagram.com/vitezslav.repa/"><img src={logo4} alt="Vítězslav Řepa"/></a></div></div>
    </div>
  );
}

export default MenuFacebook;
