import React from "react";
import './HeadlineSvatby.css';


function HeadlineSvatby() {

  return (
      <div className="headlinesvatby">
        <div className="headlinesvatby-box">
          <div className="headlinesvatby-box1"><p>Ženich nevěsta a jejich den</p></div>
          <div className="headlinesvatby-box2">Svatby</div>
          <div className="headlinesvatby-box3">
<div className="headlinesvatby-br">           
Svatba je krásný okamžik v životě člověka, který očekává příjemnou atmosféru, dobrou
náladu, zábavu a oblíbenou hudbu, která zážitek z tohoto důležitého okamžiku umocní a
pomůže uložit do paměti spolu s příjemnými vzpomínkami.
</div>
<div className="headlinesvatby-br">   
Právě v tomto duchu Vám nabízím zkušenosti DJe na svatbách.
</div>
Z bohatého hudebního archivu se Vám budu snažit připravit pestré hudební menu, které
osloví účastníky Vaší svatby od dětí až po prarodiče.
<div className="headlinesvatby-br"> 
V předsvatebním termínu se společně domluvíme na seznamu oblíbených a
neoblíbených interpretech a písničkách, které chcete, aby zazněly anebo nezazněly ve
Váš den.
</div>
<div className="headlinesvatby-br"> 
Novomanželům a svatebčanům se snažím vyjít vstříc písničkami na přání, proto vítám
Vaše nápady, které Vás v průběhu večera napadnou.
</div>
<div className="headlinesvatby-br"> 
Zastávám názor, že svatbu by nemělo v místě oslavy nic rušit, proto jezdím připravovat
aparaturu v dostatečném předstihu před příchodem svatby a tím zbytečně nerušit
pohodu svatebčanů její instalací. Díky tomu Vám decentně zahraji i ke svatebnímu
obědu, kávě a postupnému povolování opasků z dobrot, než se nálada dostane do bodu:
¨ Pojďme tancovat! ¨
</div>

          </div>
          <div className="headlinesvatby-box4"></div>
        </div>
      </div>
  );
}

export default HeadlineSvatby;


