import React from "react";
import './BodySvatby.css';
import HeadlineSvatby from './HeadlineSvatby'
import Galerie from './Galerie'


function BodySvatby() {

  return (
    <div className="bodysvatby">
       <HeadlineSvatby/>
       <Galerie/>
    </div>
  );
}

export default BodySvatby;
