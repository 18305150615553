import React from "react";
import './HeadlineOmne.css';


function HeadlineOmne() {

  return (
    <div className="headlineomne">
      <div className="headlineomne-box">
        <div className="headlineomne-box1"><p>Dovolte, abych se představil</p></div>
        <div className="headlineomne-box2">O mně</div>
        <div className="headlineomne-box3">

<div className="headlinesvatby-br"> 
Od roku 2016 se aktivně věnuji hraní a moderování různých akcí (rodinné oslavy, svatby,
firemní dny/večírky, majálesy, plesy, dětské karnevaly, dětské dny, …) nejen na území
Zlínského kraje.
</div>
<div className="headlinesvatby-br"> 
Jelikož jsem vyrůstal a vyrůstám v rodinném prostředí, kde bylo a je zprostředkování
zábavy na 1. místě, jelikož taťka Václav Řepa tuto tradici šíří přes 35 let nejen na území
Zlínského kraje, tak jsem na rodinné řemeslo navázal a pokračuji dál …<br/><br/>
Protože abyste měli Vy a Vaši hosté na akci radost, je to samozřejmě pro mě výzva a
starost.
</div>

        </div>
        <div className="headlineomne-box4"></div>
      </div>
    </div>
  );
}

export default HeadlineOmne;


