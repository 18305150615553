import React from "react";
import './Button1.css';
import {Link} from "react-router-dom"

function Button1() {

  return (
    <div className="button1">
    <Link to="/PageKontakt">Rezervace</Link>
    </div>
  );
}

export default Button1;
