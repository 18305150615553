import React from "react";
import './HeaderW.css';
import MenuW from './MenuW'
import LogoW from './LogoW'


function HeaderW() {

  return (
    <div className="headerW">
      <LogoW/>
      <MenuW/>
    </div>
  );
}

export default HeaderW;
