import React from "react";
import './PageSvatby.css';
import HeaderW from './HeaderW'
import BodySvatby from './BodySvatby'
import Footer from './Footer'

function PageSvatby() {

  return (
      <div>
      <HeaderW/>
      <BodySvatby/>
      <Footer/>
      </div>
  );
}

export default PageSvatby;
